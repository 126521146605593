<template>
  <section class="error-page">
    <h1 class="error-page__title">
      {{ $t("common.notFound") }}
    </h1>
    <div class="error-page__body">
      <svg
        class="error-page__img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          d="M0 106.538V59a4 4 0 0 1 4-4h504a4 4 0 0 1 4 4v47.538H0z"
          fill="#7a666c"
        ></path>
        <path
          d="M481.095 106.538V55H508a4 4 0 0 1 4 4v47.538h-30.905z"
          fill="#6a545a"
        ></path>
        <path
          d="M0 453V106.538h512V453a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4z"
          fill="#f1ebe0"
        ></path>
        <path
          d="M481.095 106.538V457H508a4 4 0 0 0 4-4V106.538h-30.905z"
          fill="#e8e1d7"
        ></path>
        <path
          d="M0 106.538V59a4 4 0 0 1 4-4h111.38v51.538H0z"
          fill="#ff993b"
        ></path>
        <g fill="#ffd681">
          <circle cx="29.875" cy="80.87" r="7.726"></circle>
          <circle cx="87.56" cy="80.87" r="7.726"></circle>
          <circle cx="58.72" cy="80.87" r="7.726"></circle>
        </g>
        <path
          fill="#5b5b75"
          d="M187.405 190.648a7.499 7.499 0 0 0-7.5 7.5v68.732h-57.736c-2.054 0-3.725-1.656-3.725-3.691v-65.041c0-4.143-3.357-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v65.041c0 10.307 8.399 18.691 18.725 18.691h57.736v68.731c0 4.143 3.357 7.5 7.5 7.5s7.5-3.357 7.5-7.5V198.148a7.5 7.5 0 0 0-7.5-7.5zM279.084 190.651h-54.012c-10.325 0-18.725 8.385-18.725 18.691v130.081c0 10.307 8.399 18.691 18.725 18.691h54.012c10.325 0 18.725-8.385 18.725-18.691v-130.08c0-10.307-8.4-18.692-18.725-18.692zm3.725 148.773c0 2.035-1.671 3.691-3.725 3.691h-54.012c-2.054 0-3.725-1.656-3.725-3.691V209.343c0-2.035 1.671-3.691 3.725-3.691h54.012c2.054 0 3.725 1.656 3.725 3.691v130.081zM393.214 190.648a7.499 7.499 0 0 0-7.5 7.5v68.732h-57.736c-2.054 0-3.725-1.656-3.725-3.691v-65.041c0-4.143-3.357-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v65.041c0 10.307 8.399 18.691 18.725 18.691h57.736v68.731c0 4.143 3.357 7.5 7.5 7.5s7.5-3.357 7.5-7.5V198.148a7.5 7.5 0 0 0-7.5-7.5z"
        ></path>
      </svg>
    </div>
  </section>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style lang="less" scoped>
.error-page {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;

  &__title {
    color: #eee;
    text-align: center;
    font-size: 7vw;
  }

  &__body {
    width: 100%;
    text-align: center;
  }

  &__img {
    display: inline-block;
    vertical-align: top;
    width: 70%;
    max-width: 450px;
  }
}
</style>
